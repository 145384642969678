import React from 'react'
import Styled, { keyframes } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { fadeInLeft, fadeInRight, zoomIn } from 'react-animations'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
// import Section from '../components/styles/section'
// import getStartedBG1 from '../images/getStartedBG1.jpg'
import cogs from '../images/svg/cogs.svg'
import group from '../images/svg/group.svg'

const leftAnimation = keyframes`${fadeInLeft}`
const rightAnimation = keyframes`${fadeInRight}`
const zoomAnimation = keyframes`${zoomIn}`

const BGSection = Styled(BackgroundImage)`
  position: relative;
  min-height: calc(100vh);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
`

const Container = Styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 20px;
  align-items: center;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto 20px;
  padding-top: 55px;
  @media(max-width: 801px){
    padding-top: 70px
  }
`

const InfoBlock = Styled.div`  
  background: rgba(255, 255, 255, .6);
  padding: 20px 20px 0;
  border-radius: 10px;
  @media(max-width: 801px){
    border-radius: 0;
  }
  h1{
    font-weight: 400;
  }
  .icon-container{
    width: 50%;
    max-width: 180px;
    margin: auto;
    img{
      width: 100%;
    }
  }
  &.personalized{
    animation: .85s ${leftAnimation};
  }
  &.professional{
    animation: .85s ${rightAnimation};
  }
`
const FullSpan = Styled.div`
  animation: .85s ${zoomAnimation};
  background: rgba(255, 255, 255, .6);
  padding: 20px 20px 0;
  grid-column: span 2;
  border-radius: 10px;
  @media(max-width: 801px){
    padding: 20px 10px 0;
    max-width: calc(100% - 20px);
    border-radius: 0;
  }
  h3{
    text-align: center;
    font-weight: 500;
  }
  a{
    text-decoration: none;
    color: #e06a03;
    &:hover{
      text-decoration: underline;
    }
  }
`

const GetStarted = ({ data }) => (
  <Layout>
    <SEO title="Get Started" />
    <BGSection fluid={data.imageSharp.fluid}>
      <Container>
        <InfoBlock className="personalized">
          <div className="icon-container">
            <img src={group} alt="User icon" className="icon" />
          </div>
          <h1>Personalized Service</h1>
          <p>
            Perhaps you have an idea of what you would like your site to look like. We will work with you to understand
            your vision and make it a reality. Or perhaps you don’t know what you want your site to look like, that’s
            okay too, we will work with you to understand your target audience and create a site that meets or exceeds
            your expectations.
          </p>
        </InfoBlock>
        <InfoBlock className="professional">
          <div className="icon-container">
            <img src={cogs} alt="Cog icon" className="icon" />
          </div>
          <h1>Professional Design</h1>
          <p>
            We will seamlessly integrate your company logo, colors and design elements into your website to ensure that
            your website represents your brand. If you are a start up company or are looking to re-brand we can assist
            with logo creation and brand development. Whatever your situation, we can create or improve your web
            presence.
          </p>
        </InfoBlock>
      </Container>
      <Container>
        <FullSpan>
          <h3>
            <Link to="/contact-us">Contact Us</Link> so we can get started!
          </h3>
        </FullSpan>
      </Container>
    </BGSection>
  </Layout>
)

export const query = graphql`
  query GetStartedQuery {
    imageSharp(fluid: { originalName: { eq: "getStartedBG1.jpg" } }) {
      fluid {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`
export default GetStarted
